import classes from "./Style.module.css";
import toddlerLogo from "../images/talk-dark-2.webp";
import {Link, useLocation} from "react-router-dom";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleLeft, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import React from "react";

const Header = (props)=>{
    const location = useLocation()
    const signOutHandler = props.signOutHandler
    return(<header className={`container p-3 shadow position-sticky bg-white  ${classes.header}`}>
            <div className={'row'}>
                <div className={'col-sm me-2'}>
                    <img src={toddlerLogo} alt="Toddler's Logo"/>
                </div>
                <div className={'col-sm align-content-center text-end'}>
                    {location.pathname !== '/users' && <Link to="/users"><Button className={'mx-2'} variant="outline-dark"><FontAwesomeIcon
                        icon={faAngleDoubleLeft}/> Back</Button></Link>}
                    <Button variant={"dark"} className={`btn btn-dark ${classes.signOut}`}
                            onClick={signOutHandler}><FontAwesomeIcon icon={faSignOutAlt}/> Sign Out</Button>
                </div>
            </div>
        </header>
    )
}
export default Header

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Style_signOut__2COmc:hover {\n    background-color: #fff !important;\n    color: black !important;\n}\n\n.Style_header__2OJve {\n    z-index: 10;\n    top: 0;\n}\n\n.Style_greenTick__3SweN {\n    color: limegreen;\n}\n\n.Style_redCross__3Ibhx {\n    color: orangered;\n}", "",{"version":3,"sources":["webpack://src/pages/Style.module.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,MAAM;AACV;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".signOut:hover {\n    background-color: #fff !important;\n    color: black !important;\n}\n\n.header {\n    z-index: 10;\n    top: 0;\n}\n\n.greenTick {\n    color: limegreen;\n}\n\n.redCross {\n    color: orangered;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"signOut": "Style_signOut__2COmc",
	"header": "Style_header__2OJve",
	"greenTick": "Style_greenTick__3SweN",
	"redCross": "Style_redCross__3Ibhx"
};
export default ___CSS_LOADER_EXPORT___;

import 'bootstrap/dist/css/bootstrap.css';
import Accordion from 'react-bootstrap/Accordion';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons'
import React, {useEffect, useState} from 'react';
import { useNavigate, useParams} from "react-router-dom";
import classes from './Style.module.css'
import Header from "./Header";

const UserDetails = () => {
    const navigate = useNavigate()

    const {_id} = useParams()
    const [userDetail, setUserDetail] = useState({})
    const [isError, setIsError] = useState(false)
    useEffect(() => {
        const url = `${process.env.REACT_APP_URL}/admin/user/${_id}`;

        const fetchData = async () => {
            try {
                const response = await fetch(url);
                if (response.status === 400) {
                    setIsError(true)
                }
                const data = await response.json();
                setUserDetail(data);
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();
    }, [_id, isError]);

    const signOutHandler = () => {
        localStorage.removeItem('isLoggedIn')
        navigate("/")
        console.log("Signed Out")
    }
    if (isError) {
        return (<h2 className={'text-center'}>User not found.</h2>)
    } else {
        return (
            <div className="container">
                {
                    Object.keys(userDetail).length !== 0 &&
                    <Header userDetail={userDetail} signOutHandler={signOutHandler}/>
                }
                {
                    Object.keys(userDetail).length !== 0 && <div className={'mt-3'}>
                        <div className="card">
                            <div className="card-body">
                                <div>
                                    <div className={'row'}>
                                        <div className={'col-6'}>
                                            {userDetail.user.childName && <p><b>Child
                                                Name: </b>{userDetail.user.childName ? userDetail.user.childName : ""}
                                            </p>}
                                            <p><b>Parent Name: </b>{userDetail.user.name ? userDetail.user.name : ""}
                                            </p>
                                            <p><b>Email: </b>{userDetail.user.email ? userDetail.user.email : ""}</p>
                                            <p><b>
                                                Gender: </b>{userDetail.user.childGender ? userDetail.user.childGender : ""}
                                            </p>
                                            {userDetail.user.childBirthday && <p><b>Age: </b>{`${userDetail.user.age["ageInYear"]} Yr ${userDetail.user.age["remainingMonths"]} m`}</p>}
                                        </div>
                                        <div className={'col-6'}>
                                            {userDetail.user.childBirthday && <p><b>Birthday : </b>{userDetail.user.childBirthday}</p>}
                                            {userDetail.user.lastCompletedOn && <p><b>Registration Date: </b>{userDetail.user.lastCompletedOn}</p>}
                                            {userDetail.user.isSubscribe ? (<div><p>
                                                <b>Subscription: </b>{userDetail.user.isSubscribe ? "Active" : "Inactive"}
                                            </p>
                                                <p><b>Subscription
                                                    Id: </b>{userDetail.user.subscriptionId ? userDetail.user.subscriptionId : ""}
                                                </p>
                                                <p><b>Subscription
                                                    Date: </b>{userDetail.user.subscriptionId ? userDetail.user.subscriptionId : ""}
                                                </p></div>) : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/>
                        {userDetail.quiz.length !== 0 ? userDetail.quiz.map((quiz, index) => {
                            return (
                                <Accordion key={index} className={'mb-2'}>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>{quiz.result ?
                                            <FontAwesomeIcon className={classes.greenTick} icon={faCheck}/> :
                                            <FontAwesomeIcon className={classes.redCross}
                                                             icon={faTimes}/>}<strong> &nbsp; &nbsp;{`Question ${index + 1}`} : &nbsp; </strong>{quiz.questionId ? quiz.questionId : ""}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {quiz.answers.map(answer => {
                                                return (
                                                    <main>
                                                        <p><strong>Answer : </strong>{answer ? answer : ""}</p>
                                                    </main>)
                                            })}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            )
                        }) : <h2 className={'text-center'}>Quiz not submitted.</h2>
                        }
                    </div>}
            </div>
        )
    }
}
export default UserDetails
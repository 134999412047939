import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import Header from "./Header";


require('dotenv').config();


export default function UserListPage() {
    const navigate = useNavigate()
    const [userList, setUserList] = useState({})

    useEffect(() => {
        const url = `${process.env.REACT_APP_URL}/admin/allUsers`
        const fetchData = async () => {
            try {
                const response = await fetch(url);
                const data = await response.json();
                setUserList(data)
            } catch (error) {
                console.log("error", error);
            }
        };

        fetchData();
    }, []);


    const signOutHandler = () => {
        localStorage.removeItem('isLoggedIn')
        navigate("/")
        console.log("sign Out")
    }
    return (
        <div className={'container'}>
            <Header signOutHandler={signOutHandler}/>
            {Object.keys(userList).length === 0 ? <h2 className={'text-center'}>Loading...</h2> :
                <table className="table  table-hover">
                    <thead>
                    <tr>
                        <th scope="col">Parent Name</th>
                        <th scope="col">Child Name</th>
                        <th scope="col">Child Date of Birth</th>
                        <th scope="col">Child Age</th>
                        <th scope="col">Child Gender</th>
                        <th scope="col">Email Id</th>
                        <th scope="col">Subscription</th>
                        <th scope="col">Registration Date</th>
                        <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        Object.keys(userList).length !== 0 &&
                        userList.map((user, index) => {
                            return (
                                <tr key={index}>

                                    <td>{user.name}</td>
                                    <td>{user.childName}</td>
                                    <td>{user.childBirthday ? user.childBirthday :  ""}</td>
                                    <td>{`${user.age && user.age.ageInYear ? `${user.age.ageInYear} Yr ` : ""} ${user.age && user.age.remainingMonths ? `${user.age.remainingMonths} m`: ""} `}</td>
                                    <td>{user.childGender}</td>
                                    <td>{user.email}</td>
                                    <td>{user.isSubscribe ? "Active" : "Inactive"}</td>
                                    <td>{user.registrationDate}</td>
                                    <td><Link to={`/users/${user._id}`} className="ms-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor"
                                             className="bi bi-eye" viewBox="0 0 16 16">
                                            <path
                                                d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                            <path
                                                d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                        </svg>
                                    </Link></td>
                                </tr>
                            )
                        })
                    }

                    </tbody>
                </table>}
        </div>
    )
}
import Image404 from '../images/404Image.png'
import {useNavigate} from "react-router-dom";
import {Button} from "react-bootstrap";

export default function Page404() {
    const navigate = useNavigate()
    return (
        <div className=" col ">
            <div className={'row'}>
                <img src={Image404} alt="404-Error"/>
            </div>

            <div className={'text-center'}>
                <Button variant={'secondary'} size={'lg'} onClick={() => navigate(-1)}>Go Back</Button>
            </div>
        </div>
    )
}
import Login from './pages/Login'
import UserListPage from "./pages/Users";
import {useEffect, useState} from "react";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import UserDetails from "./pages/UserDetail";
import Page404 from "./pages/Page404";
require('dotenv').config()

function App() {
    const location = useLocation()
    const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') === null ? false : localStorage.getItem('isLoggedIn'))

    const loginHandler = (val) => {
        localStorage.setItem('isLoggedIn', val)
        setIsLoggedIn(val)
    }

    useEffect(() => {
        const loginInfo = localStorage.getItem('isLoggedIn')

        if (loginInfo === "true") {
            setIsLoggedIn(true)
        } else {
            setIsLoggedIn(false)
        }
    },[location])

    return (
        <div>
            <Routes>
                <Route path="/" element={ <Navigate to="/login"/>}/>
                <Route path='/login' element={ isLoggedIn ? <Navigate to="/users" />:<Login onLogin={loginHandler}  />} />
                <Route path="/users" element={isLoggedIn ? <UserListPage /> : <Navigate to="/login"/>}/>
                <Route path="/users/:_id" element={!isLoggedIn ? <Navigate to="/login"/> : <UserDetails />} />
                <Route path="*" element={<Page404 />} />
            </Routes>
        </div>
    );
}

export default App;
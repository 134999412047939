import React, {useRef, useState} from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import {Form, Button} from "react-bootstrap";
import loginIcon from '../images/login.png'
import {useNavigate} from "react-router-dom";

require('dotenv').config();

async function loginUser(credentials) {
    return fetch(`${process.env.REACT_APP_URL}/admin/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => {
            return data
        })
}

export default function Login(props) {
    let navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('')
    const [buttonEnable, setButtonEnable] = useState(true)
    const userNameRef = useRef();
    const userPasswordRef = useRef();

    const FocusHandler = () => {
        setErrorMessage('')
    }

    const formSubmissionHandler = async (event) => {
        setButtonEnable(false)
        event.preventDefault()

        const res = await loginUser({
            userName: userNameRef.current.value,
            userPassword: userPasswordRef.current.value
        })
        console.log(res)

        if (res.status === 200) {
            setButtonEnable(false)
            props.onLogin(true)
            navigate('users')
        } else {
            setErrorMessage('wrong credential')
            setButtonEnable(true)
            props.onLogin(false)
            userNameRef.current.value = ''
            userPasswordRef.current.value = ''
        }
    }

    return (
        <div className="card w-25 mx-auto shadow" style={{marginTop: '12rem'}}>
            <div className="d-flex align-items-center flex-column mt-5">
                <div style={{width: '4rem', height: '4rem'}}>
                    <img src={loginIcon} alt="loginIcon" className="w-100 h-100"/>
                </div>
                <h4 className="text-center mt-2">Sign In</h4>
                <div className="mt-3">
                    <Form onFocus={FocusHandler} onSubmit={formSubmissionHandler}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control type="email" placeholder="Username" ref={userNameRef}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Control type="password" placeholder="Password" ref={userPasswordRef}/>
                        </Form.Group>
                        {errorMessage.trim().length > 0 && <p className="text-danger">{errorMessage}</p>}
                        <div className="text-center">
                            <Button className="mb-5" disabled={ !buttonEnable }  variant="primary" type="submit">
                                Submit
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}